import AppConst from "../AppConst";
import AuthApi from "./AuthApi";

export default class CourseificApi {

    /**
     * 
     * @param {str} text 
     * @param {Conversation} currentConversation 
     * @returns 
     */
    static async search(text) {
        let url = new URL(AppConst.BASE_URL + '/api/courses');
        url.searchParams.append('query', text);

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${AuthApi.getAuthorizationBearer()}`,
                },
            });

            if (response.status !== 200) {
                throw response.status;
            }

            return await response.json();
        } catch (e) {
            console.log(e);
            return {
                "courses": [],
                "jobs": [],
            };
            // handle error
        }

    }
}