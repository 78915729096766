import React from "react";
import CourseCardWidget from "./CourseCardWidget";


export default class CoursesWidget extends React.Component {
    componentDidMount() {
        import("./CoursesWidget.css");
        import("./CoursesWidget.mobile.css");
    }
    render() {
        return <div className="course-listing">
            {(this.props.courses.slice(0, this.props.limit)).map((c) => <CourseCardWidget key={c.id} course={c} ></CourseCardWidget>)}
        </div>
    }
}