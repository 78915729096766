export default class WebinifySpark {

    async chat(messages, onData = () => { }, onFinish = () => { }, onError = () => { }) {
        const requestBody = {
            model: "llama3.2",
            messages: messages,
            stream: true
        };

        try {
            const response = await fetch("https://ai-service.courseific.com/api/chat", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            if (response.status === 200) {
                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");
                let done = false;

                while (!done) {
                    const { value, done: readerDone } = await reader.read();
                    done = readerDone;
                    if (value) {
                        const chunk = decoder.decode(value, { stream: true });
                        // Split the chunk if it contains multiple JSON objects
                        const chunks = chunk.split('\n');  // Adjust this delimiter as needed
                
                        for (const part of chunks) {
                            if (part.trim()) { // Ignore empty parts
                                try {
                                    const jsonData = JSON.parse(part);
                                    const word = jsonData.message.content;
                                    onData(word);
                                    if (jsonData.done === true) {
                                        onFinish();
                                    }
                                } catch (e) {
                                    console.error("Error parsing JSON:", e);
                                }
                            }
                        }
                    }
                }
            } else {
                throw new Error("No response body available");
            }
        } catch (error) {
            console.error("Error during fetch:", error);
            onError(error);
        }
    }

    // async chat(messages, onData = () => { }, onFinish = () => { }, onError = () => { }) {
    //     const requestBody = {
    //         model: "llama3.2",
    //         messages: messages,
    //         stream: true
    //     };
    
    //     try {
    //         const response = await fetch("https://spark-api.webinify.com/v1/chat", {
    //             method: "POST",
    //             headers: {
    //                 "x-api-key": "a4d7766d-78da-49e4-99ff-18f28c631fb8"  // Add the API key header
    //             },
    //             body: JSON.stringify(requestBody),
    //             mode: 'no-cors' 
    //         });
    
    //         if (response.status === 200) {
    //             const reader = response.body.getReader();
    //             const decoder = new TextDecoder("utf-8");
    //             let done = false;
    
    //             while (!done) {
    //                 const { value, done: readerDone } = await reader.read();
    //                 done = readerDone;
    //                 if (value) {
    //                     const chunk = decoder.decode(value, { stream: true });
    //                     console.log(chunk);
    
    //                     // Split the chunk if it contains multiple JSON objects
    //                     const chunks = chunk.split('\n');  // Adjust this delimiter as needed
    
    //                     for (const part of chunks) {
    //                         if (part.trim()) { // Ignore empty parts
    //                             try {
    //                                 const jsonData = JSON.parse(part);
    //                                 const word = jsonData.message.content;
    //                                 onData(word);
    //                                 if (jsonData.done === true) {
    //                                     onFinish();
    //                                 }
    //                             } catch (e) {
    //                                 console.error("Error parsing JSON:", e);
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         } else {
    //             throw new Error("No response body available");
    //         }
    //     } catch (error) {
    //         console.error("Error during fetch:", error);
    //         onError(error);
    //     }
    // }
    

}


export class WebinifyMessage {
    constructor(role, content) {
        this.role = role;
        this.content = content;
    }

    toJson() {
        return {
            "role": this.role,
            "content": this.content,
        }
    }
}