import React from "react";
import FooterWidget from "./widgets/FooterWidget";
import HeaderWidget from "./widgets/HeaderWidget";


export default class HomePage extends React.Component {

    render() {
        return <>
            <HeaderWidget></HeaderWidget>


            <section className="hero typography">
                <div className="container">
                    <div className="cont-wrap">
                        <div className="content">
                            <div className="left">
                                <h1 className="home-hero-heading">Achieve your learning  <span className="flip-animate">goals</span></h1>
                                <div className="body-small-black">No more procrastination: Start Learning at the speed of thought</div>
                                <div className="btn-gp">
                                    <a href="" className="btn primary-btn">Try for Free</a>
                                    <img src="./assets/images/no-credit.png" className="no-credit-img" alt="" />
                                </div>
                            </div>
                            <div
                                className="right"
                                style={{
                                    backgroundImage: 'url(./assets/images/hero-presentation.jpg)',
                                }}
                            >
                                <video autoPlay loop muted>
                                    <source src="./assets/images/presentation-vide.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="fast-fun typography">
                <div className="container">
                    <div className="cont-wrap">
                        <h2>Courseific is <span className="txt-span-600"> Simple, Fast & Fun!</span></h2>
                        <div className="fast-card-layout">
                            <div className="fun-card">
                                <img src="./assets/images/fun-card-1.png" alt="" />
                                <div className="content">
                                    <h3>Create Your Learning Path in Seconds</h3>
                                    <p>Tell us what you want to achieve—whether it’s mastering coding, acing a test, or learning a new skill.
                                        Our AI instantly builds a <b> personalized learning path</b> just for you.</p>
                                </div>
                            </div>
                            <div className="fun-card">
                                <img src="./assets/images/fun-card-2.png" alt="" />
                                <div className="content">
                                    <h3>Get Clear Targets & Stay on Track</h3>
                                    <p>No more confusion! Your learning path breaks everything into <b> manageable steps</b>, keeping you
                                        focused with progress tracking, reminders, and milestones.</p>
                                </div>
                            </div>
                            <div className="fun-card">
                                <img src="./assets/images/fun-card-3.png" alt="" />
                                <div className="content">
                                    <h3>Achieve Your Goals with Expert Support</h3>
                                    <p> You're never alone!<b> A real human mentor</b> is here to support you, track your progress, and keep
                                        you motivated—so you stay on your path and <b> actually finish what you start.</b></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="perfect-plan typography">
                <div className="container">
                    <div className="cont-wrap">
                        <h2>The perfect learning path for every goal.</h2>
                        <p>The only course discovery platform that keeps you on track.</p>
                        <p>Find your ideal course, follow a structured plan, and get expert support—all in one </p>
                    </div>
                </div>
            </section>

           


            <section className="key-feature typography">
                <div className="container">
                    <div className="cont-wrap">
                        <h2>Key features of our <span className="txt-span-600">Learning Assistant</span></h2>
                        <p className="p-18">Find the perfect course, follow a personalized learning path, and stay on track with expert
                            mentorship—Courseific makes learning effortless, focused, and results-driven!</p>
                    </div>

                    <div className="feature-bottom-container">
                        <div className="block-layout">
                            <div className="home-feature-item-6">
                                <div className="home-feature-icon-wrapper _1">
                                    <img src="./assets/images/effortless_creation.svg" loading="eager"
                                        alt="Creation icon" className="home-feature-icon" /></div>

                                <h3>AI-Powered Course Search</h3>

                                <p>No more endless searching! Just tell us what you need & our AI will instantly suggest the best courses.
                                </p>
                            </div>
                            <div className="home-feature-item-6">
                                <div className="home-feature-icon-wrapper _2">
                                    <img src="./assets/images/personalized_design.svg" loading="eager"
                                        alt="Personalized design" className="home-feature-icon" /></div>

                                <h3>100s in 1 Platform</h3>

                                <p>We bring together top courses from 100s of platforms like Udemy, Coursera, edX, to name a few all under
                                    one roof.
                                </p>
                            </div>
                            <div className="home-feature-item-6">
                                <div className="home-feature-icon-wrapper _3"><img src="./assets/images/Folder.svg" loading="eager" alt=""
                                    className="home-feature-icon" /></div>

                                <h3>Achieve Your Learning Targets</h3>

                                <p>Want a structured learning journey? Our curated paths guide you step by step, unlocking new courses as
                                    you progress toward your goal.
                                </p>
                            </div>
                            <div className="home-feature-item-6">
                                <div className="home-feature-icon-wrapper _4"><img src="./assets/images/seamless_sharing.svg" loading="eager"
                                    alt="Share icon" className="home-feature-icon" /></div>

                                <h3>Mentor Support for Real Guidance</h3>

                                <p>Get personal guidance from experienced mentors.</p>
                            </div>
                            <div className="home-feature-item-6">
                                <div className="home-feature-icon-wrapper _5"><img src="./assets/images/analytics_tracking.svg" loading="eager"
                                    alt="Analytics icon" className="home-feature-icon" /></div>

                                <h3>Get Job-Ready Faster! </h3>

                                <p>Find your perfect course and discover job opportunities tailored to your skills.</p>
                            </div>
                            <div className="home-feature-item-6">
                                <div className="home-feature-icon-wrapper _6"><img src="./assets/images/responsive_design.svg" loading="eager"
                                    alt="Responsive icon" className="home-feature-icon" /></div>

                                <h3>Curious Community</h3>

                                <p>Learn with others! Join discussions, share experiences, and connect with like-minded learners to stay
                                    motivated.
                                </p>
                            </div>
                            <div className="home-feature-item-6">
                                <div className="home-feature-icon-wrapper _7"><img src="./assets/images/multiple-device_compatibility.svg"
                                    loading="eager" alt="Multiple device" className="home-feature-icon" /></div>

                                <h3>Analytics & Insights for Users</h3>

                                <p>Track your progress, receive AI-powered learning reports, and get notified about new courses and
                                    discounts tailored to your interests.</p>
                            </div>
                            <div className="home-feature-item-6">
                                <div className="home-feature-icon-wrapper _8"><img src="./assets/images/live_collaboration.svg" loading="eager"
                                    alt="Collaboration icon" className="home-feature-icon" /></div>

                                <h3>Multi-Device Compatibility</h3>

                                <p> Learn anytime, anywhere! Courseific works seamlessly across desktops, tablets, and mobile devices,
                                    ensuring a smooth experience at home or on the go.
                                </p>
                            </div>
                            <div className="home-feature-item-6 ext">
                                <div className="home-feature-icon-wrapper"><img src="./assets/images/Icon.svg" loading="eager" alt="icon"
                                    className="home-feature-icon" /></div>

                                <h3>Smart Progress Reminders & Deadlines</h3>
                                <p>Stay on track! Courseific sends personalized reminders, progress updates, and AI-driven study schedules
                                    to help you complete your courses on time and reach your learning goals.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

            </section >
            <section className="template typography">
                <div className="container">
                    <div className="cont-wrap">
                        <div className="row ">
                            <div className="col-md-6 ">
                                <div className="col-right">
                                    <div className="content">
                                        <h2>Find a <span className="txt-span-600">Course in Seconds </span> – No More Endless Searching!</h2>
                                        <p className="p-18">
                                            Tired of scrolling through countless websites to find the right course? Courseific does the hard
                                            work for you! Just type what you need—like “Become a Frontend Developer”—and our AI-powered search
                                            instantly finds the best courses across top platforms. No guesswork, no wasted time—just the perfect
                                            course, in seconds. 🚀
                                            <br />
                                            It’s Free Forever.
                                        </p>
                                        <img src="./assets/images/Orange Circle.svg" className="best-tools-2-yellow-tag" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-left">
                                    <img src="./assets/images/ideatodeck.png" alt="" />

                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="col-left">
                                    <img src="./assets/images/brand_consistent.png" alt="" />

                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="col-right">
                                    <div className="content">
                                        <h2>Learn Anything, From Any <span className="txt-span-600"> Niche! </span></h2>
                                        <p className="p-18">Whether you're preparing for GRE, mastering Data Science, diving into Healthcare, or
                                            exploring AI, Courseific has you covered! We bring together the best courses from every niche, so no
                                            matter what you want to learn, you'll find the right path—all in one place. 🚀</p>
                                        <img src="./assets/images/Purple Circle.svg" className="best-tool-3-purple-tag" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
          
            

            <section className="thought typography">
                <img src="./assets/images/thought-img-1.webp" alt="Template" className="cta-item-1-image" />
                <img src="./assets/images/thought-img-2.webp" className="cta-item-2-image" />
                <div className="wrapper">
                    <div className="cta-wrapper">
                        <h2 className="text-light">Start Learning at the speed of thought.</h2>
                        <div className="button-container">
                            <a href="#" className="btn primary-btn">Start for Free</a>
                            <img src="./assets/images/No Credit Card White Item.png" loading="lazy" alt="No credit card required"
                                className="image-66" />
                        </div>
                    </div>
                </div>
            </section>

            <FooterWidget></FooterWidget>

            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.css" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css" />
            <link rel="stylesheet" href="./assets/css/main.css" />
            <link rel="stylesheet" href="./assets/css/responsive.css" />

            <script src="https://code.jquery.com/jquery-3.7.1.min.js"></script>
            
            <script src="./assets/js/main.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js"></script>
            <script defer src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"></script>
        </>
    }
}