import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ConversationRepository from "../../repository/ConversationRepository";

import { MessageSender } from "../../model/Message";
import WebinifySpark from "../../api/WebinifySpark";
import AppConfig from "../../AppConfig";
import MessageRepository from "../../repository/MessageRepository";
import MessageEntity from "../../entity/MessageEntity";
import ConversationAPI from "../../api/ConversationApi";

export default class ConversationsPaneWidget extends React.Component {
    componentDidMount() {
        import ('./ConversationsPaneWidget.css');
        import ('./ConversationsPaneWidget.mobile.css');
    }

    render() {
        return <>
            {/* Only show the overlay if the sidebar is visible */}
            {this.props.isVisible && <div className="overlay transparent" onClick={this.props.toggleConversations}></div>}

            <div className={`conversation-widget ${this.props.isVisible ? 'visible' : ''}`}>

                {this.props.conversations ? Object.entries(ConversationRepository.categorizeConversations(this.props.conversations))
                    .map(([key, value], index) => {
                        return {
                            index: index,
                            category: key,
                            list: value
                        };
                    })
                    .filter((c) => c.list.length > 0)
                    .map(c => {
                        let conversations = c.list;
                        conversations.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

                        return <>
                            <span key={c.index} className="chat-sidebar-1-span">{c.category}</span>
                            {conversations.map(conversation => <ConversationLineWidget conversation={conversation}
                                onClick={() => {
                                    this.props.onConversationClick(conversation.uuid);
                                    this.props.toggleConversations();
                                }}
                                onDelete={() => {
                                    this.props.deleteConversation(conversation.uuid);
                                    this.props.toggleConversations();
                                }} />)}
                        </>;
                    }
                    ) : <></>}


            </div>
        </>
    }
}

class ConversationLineWidget extends React.Component {
    constructor(props) {
        super(props);
        this.webinifySpark = new WebinifySpark();

        this.state = {
            conversation: props.conversation,
            firstMessage: props.firstMessage,
            isFinish: false,
            isRunning: false
        }

        this.indexDb = indexedDB.open(AppConfig.DATABASE_NAME, AppConfig.VERSION);
        this.db = null;

        this.indexDb.addEventListener('success', (ev) => {
            this.db ??= ev.target.result;
            this.conversationRepository ??= new ConversationRepository(this.db);
            this.messageRepository ??= new MessageRepository(this.db);

            // For now, we will not rewrite the title
            // this.updateTitle();
        });
    }

    updateTitle() {
        if (this.state.isRunning) return; // Prevent multiple executions

        this.setState({ isRunning: true });

        const loop = () => {
            this.messageRepository.getBy({ conversationId: this.state.conversation.id }).then((res) => {
                let messages = MessageEntity.fromList(res)
                    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
                const firstMessage = messages[0];

                if (this.state.conversation.title === "" || this.state.conversation.title === ".") {
                    this.webinifySpark.chat(
                        [
                            {
                                "role": MessageSender.ASSISTANT,
                                "content": "Give a title to this prompt that does not exceed 7 words: " + firstMessage.prompt
                            },
                        ],
                        (word) => {
                            // Append words as they stream
                            this.setState((prevState) => ({
                                conversation: prevState.conversation.addWord(word),
                            }));
                        },
                        async () => {
                            const conversation = await ConversationAPI.post(this.state.conversation);
                            // On completion, update conversation
                            await this.conversationRepository.update(conversation);

                            if (this.state.conversation.title === "" || this.state.conversation.title === ".") {
                                setTimeout(loop, 1000); // Continue looping if the title is still invalid
                            } else {
                                this.setState({ isRunning: false }); // Stop looping
                            }
                        },
                        () => {
                            // On error, mark as finished but retry
                            this.setState({ isRunning: false });
                            setTimeout(loop, 1000); // Retry after 1 second
                        }
                    );
                } else {
                    this.setState({ isRunning: false });
                }
            });
        };

        loop();
    }


    render() {
        return <div className="chat-sidebar-field">
            <button data-active={this.state.conversation.isActive === true} onClick={() => {
                this.props.onClick(this.state.conversation.id);
            }}>{this.state.conversation.title ?? "Current conversation"}</button>
            <button onClick={() => {
                this.props.onDelete(this.state.conversation.id);
            }} className="delete-conversation">
                <FontAwesomeIcon icon={faTimes} />
            </button>
        </div>
    }
}