import React from "react";


export default class HeaderWidget extends React.Component {
    componentDidMount() {
        import("./HeaderWidget.css");
    }

    render() {
        return <header>
            <div className="container">


                <div className="cont-wrap">
                    <nav className="navbar navbar-expand-lg bg-body-tertiary p-0">
                        <a className="navbar-brand" href="/">
                            <img src="https://www.courseific.com/blog/wp-content/uploads/2024/09/logo-no-background-1.svg" alt="" /></a>
                        <div className="toggler-div">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="/">Why Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Pricing</a>
                                </li> */}

                            </ul>
                            <div className="sign-up mob-btn">
                                <a href="/chat" className="secondary-btn ">Login</a>
                                <a href="/chat" className="primary-btn">Try for Free</a>
                            </div>
                        </div>

                        <div className="sign-up">
                            {/* <div className="dropdown">

                                <button className="btn language-selector dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="https://flagcdn.com/w40/gb.png" alt="English" className="flag-icon" />
                                    <span>English</span>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <img src="https://flagcdn.com/w40/gb.png" alt="English" className="flag-icon" /> English
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <img src="https://flagcdn.com/w40/es.png" alt="Español" className="flag-icon" /> Español
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <img src="https://flagcdn.com/w40/fr.png" alt="Français" className="flag-icon" /> Français
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <img src="https://flagcdn.com/w40/de.png" alt="Deutsch" className="flag-icon" /> Deutsch/
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                            <a href="/chat" className="secondary-btn ">Login</a>
                            <a href="/chat" className="primary-btn">Try for Free</a>
                        </div>
                    </nav>
                </div>



            </div>
        </header >;
    }
}