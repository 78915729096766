import AppConst from "../AppConst";

export default class AuthApi {

    static async logout() {
        try {
            const refreshToken = localStorage.getItem('refresh-token');
            const token = localStorage.getItem('token');

            localStorage.removeItem('token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('app-email');
            localStorage.removeItem('app-first_name');
            localStorage.removeItem('app-last_name');
            
            const response = await fetch(AppConst.BASE_URL + '/auth/token/invalidate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    "refresh_token": refreshToken
                })
            });

            if (!response.ok) {
                throw new Error('Failed to logout');
            }

            return true;
        } catch (error) {
            console.error('Login error:', error);
            return false;
        }
    }

    static isLoggedIn() {
        const token = localStorage.getItem('token');
        return token ? true : false;
    }

    static getUserId() {
        return localStorage.getItem('app-user');
    }
    
    static getAuthorizationBearer() {
        const token = localStorage.getItem('token');
        return token;
    }

    static async login(email, password) {
        try {
            const response = await fetch(AppConst.BASE_URL + '/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });

            if (!response.ok) {
                throw new Error('Failed to login');
            }

            const data = await response.json();
            localStorage.setItem('token', data.token);
            localStorage.setItem('refresh-token', data.refresh_token);
            return true;
        } catch (error) {
            console.error('Login error:', error);
            return false;
        }
    }

    static async refresh() {
        try {
            const refreshToken = localStorage.getItem('refresh-token');
            const token = localStorage.getItem('token');
            const response = await fetch(AppConst.BASE_URL + '/auth/token/refresh', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    "refresh_token": refreshToken
                })
            });

            if (!response.ok) {
                throw new Error('Failed to login');
            }

            const data = await response.json();
            localStorage.setItem('token', data.token);
            localStorage.setItem('refresh-token', data.refresh_token);
            return true;
        } catch (error) {
            console.error('Login error:', error);
            return false;
        }
    }

    static async userProfile() {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(AppConst.BASE_URL + '/api/user/me', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token
                },
            });

            if (!response.ok) {
                throw new Error('Failed to login');
            }

            const data = await response.json();
            localStorage.setItem('app-email', data.email);
            localStorage.setItem('app-first_name', data.firstName);
            localStorage.setItem('app-last_name', data.lastName);
            return data;
        } catch (error) {
            if(await AuthApi.refresh() === true) {
                return AuthApi.userProfile();
            }
            console.error('Login error:', error);
            throw error;
        }
    }

    static async register(firstName,lastName, email, password) {
        try {
            const response = await fetch(AppConst.BASE_URL + '/api/user/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    password: password
                })
            });

            if (!response.ok) {
                throw new Error('Failed to register');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Registration error:', error);
            throw error;
        }
    }
}
