import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Chat from "./view/Chat";
import './index.css';
import HomePage from "./view/HomePage";
import LoginPage from "./view/LoginPage";
import PricingPage from "./view/PricingPage";

// app launch
//  https://chatgpt.com/c/67188c96-2c3c-8004-8be0-5af37b623eeb

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/pricing" element={<PricingPage />} />
      </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

