import { faBars, faMessage, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from 'react';
import SideBarWidget from './SideBarWidget.js';
import ConversationsPaneWidget from './ConversationsPaneWidget.js';

import AppLogo from '../../assets/logo.png';
import AuthApi from '../../api/AuthApi.js';

export default class AppHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false, // Sidebar is initially hidden
            isProfileMenuOpen: false,
            isConversationMenuOpen: false,
            isLoggedIn: this.props.isLoggedIn
        };


        this.onNewConversationClicked = props.onNewConversationClicked;
    }

    componentDidMount() {
        import ('./AppHeader.css');
        import ('./AppHeader.mobile.css');
    }

    // Method to toggle the sidebar visibility
    toggleSidebar = () => {
        this.setState((prevState) => ({
            isVisible: !prevState.isVisible
        }));
    };

    // Handle toggling of the profile menu dropdown
    toggleProfileMenu = () => {
        this.setState((prevState) => ({
            isProfileMenuOpen: !prevState.isProfileMenuOpen
        }));
    };

    toggleConversations = () => {
        this.setState((prevState) => ({
            isConversationMenuOpen: !prevState.isConversationMenuOpen
        }));
    };


    logout = () => {
        AuthApi.logout();
        this.props.setIsLoggedIn(false);
        this.setState((prevState) => ({
            isProfileMenuOpen: false
        }));
    }

    render() {
        const { onNewConversationClicked, onConversationClick, deleteConversation } = this.props;
        return <>
            <header className='app-header'>
                <div className='left'>
                    <button onClick={() => this.toggleSidebar()}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <a className='app-logo' href='/'>
                        <img src={AppLogo} alt='logo'></img>
                    </a>
                </div>
                <div className='profile'>
                    <button className='tooltip' onClick={onNewConversationClicked}>
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="tooltiptext">New Chat</span>
                    </button>
                    <div style={{ position: 'relative' }}>
                        <button className='tooltip' onClick={this.toggleConversations}>
                            <FontAwesomeIcon icon={faMessage} />
                            <span className="tooltiptext">All messages</span>
                        </button>
                        <ConversationsPaneWidget toggleConversations={this.toggleConversations} deleteConversation={deleteConversation} onConversationClick={onConversationClick} isVisible={this.state.isConversationMenuOpen} conversations={this.props.conversations} ></ConversationsPaneWidget>
                    </div>
                    {this.renderAuthArea()}
                </div>
            </header>
            <SideBarWidget isVisible={this.state.isVisible} toggleSidebar={this.toggleSidebar}></SideBarWidget>
        </>
    }


    renderAuthArea() {
        if (this.props.isLoggedIn) {
            return this._renderProfile();
        } else {
            return this._renderLogin();
        }
    }

    getUserName() {
        const firstName = localStorage.getItem('app-first_name');
        const lastName = localStorage.getItem('app-last_name');
        const username = localStorage.getItem('app-username');

        if (firstName && lastName) {
            return `${firstName} ${lastName}`; // Return first name + last name if both are present
        } else {
            return username; // Return username if first name and last name are empty
        }
    }


    _renderProfile() {
        const { isProfileMenuOpen } = this.state;

        return (
            <div className="profile-area">
                {/* Profile Icon (can be replaced with an image if needed) */}
                <button className="profile-button" onClick={this.toggleProfileMenu}>
                    {localStorage.getItem('app-avatar') ? <div className='profile-icon'>
                        <img src={localStorage.getItem('app-avatar')} alt='Profile'></img>
                    </div> : <></>}



                    <span>{this.getUserName()}</span>
                </button>

                {/* Dropdown Menu */}
                {isProfileMenuOpen && (
                    <div className="profile-menu">
                        <ul>
                            <li>Logged in as {localStorage.getItem('app-email')}</li>
                            <li onClick={this.logout}>Logout</li>
                        </ul>
                    </div>
                )}
            </div>
        );
    }

    _renderLogin() {
        const { setIsLoginFormActive } = this.props;
        return (
            <>
                <button className="login-button" onClick={() => setIsLoginFormActive(true)}>
                    Sign In
                </button>
            </>
        );
    }


}