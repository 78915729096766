import React from "react";

import AuthApi from "../../api/AuthApi";
import SuccessSnackbar from "./SuccessSnackBar";
import ErrorSnackbar from "./ErrorSnackBar";


export default class LoginForm extends React.Component {
    static LOGIN = 1;
    static SIGNUP = 2;

    constructor(props) {
        super(props);

        this.state = {
            selectedForm: props.selectedForm ?? LoginForm.LOGIN,
            successOpen: false,
            errorOpen: false,
            errorMessage: '',
            isLoading: false
        }
    }

    componentDidMount() {
        import("./LoginForm.css");
    }

    toggleForm = () => {
        this.setState((prevState) => ({
            selectedForm: prevState.selectedForm === LoginForm.LOGIN ? LoginForm.SIGNUP : LoginForm.LOGIN
        }));
    }

    handleSuccessClose = () => {
        this.setState({ successOpen: false });
    };

    handleErrorClose = () => {
        this.setState({ errorOpen: false });
    };

    wrapOverlay(html) {
        return <>
            <SuccessSnackbar
                open={this.state.successOpen}
                onClose={this.handleSuccessClose}
            />

            <ErrorSnackbar
                open={this.state.errorOpen}
                onClose={this.handleErrorClose}
                errorMessage={this.state.errorMessage}
            />

            <div className="auth-container">
                {html}
            </div>
        </>
    }


    renderSignup() {
        return this.wrapOverlay(
            <div className="form-container signup-container">
                <form onSubmit={this.handleSignupSubmit}>
                    <h2 className="title">Sign Up</h2>

                    <div className="input-field">
                        <input
                            type="email"
                            name="email"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Email</label>
                        <span className="focus-border"></span>
                    </div>
                    <div className="input-field">
                        <input
                            type="text"
                            name="firstName"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>First Name</label>
                        <span className="focus-border"></span>
                    </div>
                    <div className="input-field">
                        <input
                            type="text"
                            name="lastName"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Last name</label>
                        <span className="focus-border"></span>
                    </div>
                    <div className="input-field">
                        <input
                            type="password"
                            name="password"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Password</label>
                        <span className="focus-border"></span>
                    </div>
                    <button type="submit" className="btn" disabled={this.state.isLoading} >Sign Up</button>

                </form>
                <p className="text" >
                    Already have an account? <button onClick={this.toggleForm} id="signIn">Login</button>
                </p>
            </div>
        );
    }


    // Handle signup form submission
    handleSignupSubmit = (event) => {
        this.setState({
            isLoading: true
        });
        event.preventDefault(); // Prevent the default form submission behavior

        const { firstName, lastName, email, password } = this.state;

        // Add your signup logic here, such as calling an API
        // Example:
        AuthApi.register(firstName, lastName, email, password)
            .then(response => {
                AuthApi.login(email, password)
                    .then(response => {
                        this.setState({ successOpen: true });

                        // auto login
                        AuthApi.userProfile()
                            .then(response => {
                                this.setState({
                                    isLoading: false,
                                    successOpen: true
                                });

                                this.props.setIsLoggedIn(true);
                                this.props.setIsLoginFormActive(false);
                            })
                            .catch(error => {
                                this.setState({
                                    isLoading: false,
                                    errorMessage: error.message,
                                    errorOpen: true
                                });
                            });
                    })
                    .catch(error => {
                        this.setState({
                            errorMessage: error.message,
                            errorOpen: true
                        });
                    });
            })
            .catch(error => {
                // Handle error
            });
    }

    // Handle form input changes
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    renderLogin() {
        return this.wrapOverlay(
            <div className="form-container login-container">
                <form onSubmit={this.handleSubmit}>
                    <h4 className="sub-title">You must login to continue chatting</h4>
                    <h2 className="title">Login</h2>
                    <div className="input-field">
                        <input
                            type="email"
                            name="email"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Email</label>
                        <span className="focus-border"></span>
                    </div>
                    <div className="input-field">
                        <input
                            type="password"
                            name="password"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Password</label>
                        <span className="focus-border"></span>
                    </div>
                    <button type="submit" className="btn" disabled={this.state.isLoading} >Login</button>
                </form>
                <p className="text">
                    Don't have an account? <button onClick={this.toggleForm} id="signUp">Sign Up</button>
                </p>
            </div>
        );
    }

    // Handle form submission
    handleSubmit = (event) => {
        this.setState({
            isLoading: true,
        });
        event.preventDefault(); // Prevent the default form submission behavior
        const { email, password } = this.state;

        // Add your login logic here, such as calling an API
        // Example:
        AuthApi.login(email, password)
            .then(response => {
                AuthApi.userProfile()
                    .then(response => {
                        this.setState({
                            isLoading: false,
                            successOpen: true
                        });
                        this.props.setIsLoggedIn(true);
                        this.props.setIsLoginFormActive(false);
                    })
                    .catch(error => {
                        this.setState({
                            isLoading: false,
                            errorMessage: error.message,
                            errorOpen: true
                        });
                    });
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message,
                    errorOpen: true
                });
            });
    }

    render() {
        if (this.props.isLoginFormActive === true) {
            if (this.state.selectedForm === LoginForm.LOGIN) {
                return this.renderLogin();
            } else {
                return this.renderSignup();
            }
        } else {
            return <></>;
        }
    }

}