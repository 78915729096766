import AppConst from "../AppConst";
import MessageEntity from "../entity/MessageEntity";
import AuthApi from "./AuthApi";


export default class MessageAPI {

    /**
     * @deprecated
     */
    static async getRemoteMessages() {
        if (AuthApi.isLoggedIn()) {

            const response = await fetch(`${AppConst.BASE_URL}/api/messages`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${AuthApi.getAuthorizationBearer()}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            if (response.ok) {
                // here save all the conversations
                const jsonData = await response.json();
                const messages = MessageEntity.fromList(jsonData);
                return messages;
            } else {
                if (await AuthApi.refresh()) {
                    return this.getRemoteMessages();
                }
            }
        }

    }


    /**
     * 
     * @param {MessageEntity} message 
     * @returns {Promise<MessageEntity>}
     */
    static async post(message) {
        if (AuthApi.isLoggedIn()) {
            const API = message.isSynced()
                ? `${AppConst.BASE_URL}/api/messages/${message.id}`
                : `${AppConst.BASE_URL}/api/messages`;

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${AuthApi.getAuthorizationBearer()}`,
                },
                body: message.toJson()
            });

            if (response.ok) {
                const jsonResponse = await response.json();
                message.id = jsonResponse.id;
                return message;
            } else {
                if (await AuthApi.refresh()) {
                    return this.post(message);
                }
            }
        }

    }

}