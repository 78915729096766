import React, { Component } from 'react';
import { Snackbar, Alert } from '@mui/material';

class ErrorSnackbar extends Component {
    handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const { open, errorMessage } = this.props;

        return (
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={this.handleClose}
                action={
                    <button onClick={this.handleClose}>Close</button>
                }
            >
                <Alert onClose={this.handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage || 'An error occurred'}
                </Alert>
            </Snackbar>
        );
    }
}

export default ErrorSnackbar;
