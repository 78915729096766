import { Divider } from "@mui/material";
import React from "react";


export default class FooterWidget extends React.Component {

    render() {
        return <footer className="footer-section typography">
            <div className="container-fluid">
                <div className="footer-wrap">
                    <div className="row">
                        <div className="col-6 col-md-3">
                            <div className="col-wrap footer-logo">
                                <img src="./assets/images/logo-no-background.svg" alt="" />
                                <p>Stay updated with the latest courses in online learning. Courseific is your go-to blog for top courses and educational news.</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="col-wrap">
                                <h4>Company</h4>
                                <div className="link-group">
                                    <a href="https://www.courseific.com/blog/about-us/">About Us</a>
                                    <a href="./pricing">Pricing</a>
                                    
                                    <a href="https://www.courseific.com/blog/contact-us/">Contact us</a>
                                    <a href="https://www.courseific.com/blog/partner-with-us/">Partner with Us</a>
                                    <a href="https://www.courseific.com/blog/careers/">Careers at Courseific</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="col-wrap">
                                <h4>Trending</h4>
                                <div className="link-group col-third">
                                    <a href="#">AI Presentation Maker</a>
                                    <a href="#">
                                        <h3>Become a PLC Expert: The Best Udemy Courses for Programmable Logic Controllers In 2025</h3></a>
                                </div>
                                <div className="link-group col-third">
                                    <a href="#">Design</a>
                                    <a href="#">
                                        <h3>Best Udemy Courses for Adobe Illustrator in 2025</h3></a>
                                </div>
                                <div className="link-group col-third">
                                    <a href="#">IT & Software</a>
                                    <a href="#">
                                        <h3>Mastering Linux Courses for Every Skill Level in 2025</h3></a>
                                </div>


                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="col-wrap">
                                <h4>Categories</h4>
                                <div className="cat-group">
                                    <a href="#">Business</a>
                                    <a href="#">65</a>
                                </div>
                                <div className="cat-group">
                                    <a href="#">Programming</a>
                                    <a href="#">34</a>
                                </div>
                                <div className="cat-group">
                                    <a href="#">Marketing</a>
                                    <a href="#">17</a>
                                </div>
                                <div className="cat-group">
                                    <a href="#">Digital Marketing</a>
                                    <a href="#">15</a>
                                </div>
                                <div className="cat-group">
                                    <a href="#">Data Science</a>
                                    <a href="#">12</a>
                                </div>
                                <div className="cat-group">
                                    <a href="#">Computer Science</a>
                                    <a href="#">10</a>
                                </div>
                                <div className="cat-group">
                                    <a href="#">Personal Development</a>
                                    <a href="#">9</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <hr style={{
                    display: 'block',
                    height: '1px',
                    border: '0',
                    borderTop: '1px solid #545479',
                    margin: '2em 0',
                    padding: '0',
                    opacity: 0.2
                }} />

                <div className="footer-bottom-block">

                    <div className="copyright">
                        <div className="term-link">
                            <a href="#">Terms of Use</a>
                            <a href="#">Privacy Policy</a>
                            <a href="#">Site Map</a>
                        </div>
                        <p>© Courseific</p>


                        <div className="social">
                            <a href="#"><i className="fa-brands fa-linkedin"></i></a>
                            <a href="#"><i className="fa-brands fa-youtube"></i></a>
                            <a href="#"><i className="fa-brands fa-x-twitter"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
            ;
    }
}