

export default class AppConst {
    static BASE_URL = 'https://api.courseific.com';
    // static BASE_URL = 'http://localhost:2015';

    static APP_NAME = 'Courseific';

    static POST_PUBLISH = 'publish';
    static POST_DRAFT = 'draft';
}