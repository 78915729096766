import React from "react";
import MessageWidget from "./MessageWidget";
import WebinifySpark from "../../api/WebinifySpark";
import { MessageSender } from "../../model/Message";


export default class ConversationContainerWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            conversation: props.conversation
        }

        this.webinifySpark = new WebinifySpark();
    }

    render() {
        let messages = this.state.conversation.getMessages();
        let filteredMessages = messages.filter((m) => m.role !== MessageSender.SYSTEM);

        return filteredMessages.map(
            m => <MessageWidget setConversation={this.props.setConversation} scrollToBottom={this.props.scrollToBottom} onAssistantMessageAdded={this.props.onAssistantMessageAdded} onFinish={this.props.onMessageFinish} message={m} conversation={this.state.conversation} />
        );
    }
}