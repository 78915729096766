import React from "react";



export default class WelcomeMessage extends React.Component {
  componentDidMount() {
    import('./WelcomeMessage.css');
    import('./WelcomeMessage.mobile.css');
  }
  render() {
    return <div className="welcome-container">
      <div className="prompt-box">
        {this.props.promptExamples.map(p => <div key={p.id} onClick={() => this.props.triggerSearch(p.text)} className="prompt">
          {p.icon}
          <p><strong>{p.text}</strong></p>
        </div>)}
      </div>
    </div>
  }
}