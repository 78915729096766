import React from "react";


export default class CategoriesWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        import("./CategoriesWidget.css");
    }

    getCategories(courses) {
        let categories = new Set();
        courses?.forEach(course => {
            course.categories.forEach(category => categories.add(category));
        });
        return Array.from(categories);
    }
    

    render() {
        let { courses } = this.props;
        let categories = this.getCategories(courses);

        return <>
            {categories.length > 0 ? <>
                <div className="course-categories" data-visible={this.props.visible}>
                    <h4>Related categories</h4>
                    <div className="list">
                        {categories.length > 0 ? categories.map((category, index) => (
                            <a key={index} rel="norefferer" onClick={(e) => this.props.onCategoryClicked(e, category)} className="category" href={category.slug} dangerouslySetInnerHTML={{ __html: category.name }}></a>
                        )) : <></>}
                    </div>
                </div>
            </> : <></>}</>
    }
}