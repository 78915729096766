import AppConst from "../AppConst";
import ConversationEntity from "../entity/ConversationEntity";
import AuthApi from "./AuthApi";

export default class ConversationAPI {

    static async getAll() {
        if (AuthApi.isLoggedIn()) {
            const response = await fetch(`${AppConst.BASE_URL}/api/conversations`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${AuthApi.getAuthorizationBearer()}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            if (response.ok) {
                const jsonData = await response.json();
                let conversation = ConversationEntity.fromList(jsonData);
                conversation.forEach((conversation) => {
                    conversation.messages = conversation.messages?.map((message) => {
                        message.conversationId = conversation.id;
                        return message;
                    });
                });
                return conversation;
            } else {
                if(await AuthApi.refresh()) {
                    return this.getAll();
                }
                return [];
            }
        }
    }

    /**
     * This function will send to remote database
     * @param {ConversationEntity} conversation 
     * @returns {Promise<ConversationEntity>}
     */
    static async post(conversation) {
        if (AuthApi.isLoggedIn()) {
            const API = conversation.id !== 0
                ? `${AppConst.BASE_URL}/api/conversations/${conversation.id}`
                : `${AppConst.BASE_URL}/api/conversations`;
            
            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${AuthApi.getAuthorizationBearer()}`,
                },
                body: conversation.toJson(),
            });

            if (response.ok) {
                const jsonResponse = await response.json();
                conversation.id = jsonResponse.id;
                return conversation;
            } else {
                if(await AuthApi.refresh()) {
                    return this.post(conversation);
                }
                throw new Error(`Error creating/updating conversation: ${response.statusText}`);
            }
        }
    }
}
