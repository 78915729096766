import React from "react";
import FooterWidget from "./widgets/FooterWidget";
import HeaderWidget from "./widgets/HeaderWidget";


export default class PricingPage extends React.Component {

    render() {
        return <>

            <HeaderWidget></HeaderWidget>

            <section className="pricing typography">
                <div className="container">
                    <div className="pricing-hero-wrapper">
                        <div className="pricing-center-container">
                            <h1 className="pricing-h1-heading">The <span className="txt-span-600">perfect plan</span> for every team</h1>
                            <p className="body-small pricingcenteralign">The only presentation &nbsp;software that delivers stunning returns</p>
                            <p className="p-20">The prices shown below are for the US. Your actual price might vary depending on your country to adjust for purchasing power parity.</p>
                        </div>

                        <div className="pricing-card-layout">
                            <div className="price-card">
                                <div className="div-block">
                                    <h3>STARTER</h3>
                                    <img src="https://cdn.prod.website-files.com/63ca9a05fdc83042565f605c/63e3840698dcc96c89ceed60_personalized_design.svg" width="24" height="27" alt="Personalized design" className="image-2" />
                                </div>
                                <div className="price-tag">Free</div>
                                <p className="p-dec">EARLY BIRD PRICING</p>
                                <a href="#" className="price-btn">Save 100% for a limited time</a>
                                <p className="p-time">For AI course search</p>
                                <div className="pricing-points-contain">
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>One Learning Path (Generalised)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>AI Course Search (Limited)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Assessment</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Mentorship Calls (1 Call)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Personalized Recommendations</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Learning Guidance</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Progress Track</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Priority Support</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Exclusive Webinars</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Certificate of Completion</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Team & Corporate Access</p>
                                    </div>
                                </div>
                                <a data-tracker="PricingStarter|Signup" href="#" target="_blank" className="white-button pricing logintrackbutton w-button">Sign Up</a>
                            </div>
                            <div className="price-card">
                                <div className="div-block">
                                    <h3>Pro</h3>
                                    <img src="https://cdn.prod.website-files.com/63ca9a05fdc83042565f605c/63e3840698dcc96c89ceed60_personalized_design.svg" width="24" height="27" alt="Personalized design" className="image-2" />
                                </div>
                                <div className="price-tag"><span className="dis-price">$599</span> $299</div>
                                <p className="p-dec">EARLY BIRD PRICING</p>
                                <a href="#" className="price-btn">Save 49.9% for a limited time</a>
                                <p className="p-time">For structured learning</p>
                                <div className="pricing-points-contain">
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>One Learning Path (Personalised)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>AI Course Search (Unlimited)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Assessment (Personalised)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Mentorship Calls (Private Strategy Calls)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Personalized Recommendations</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Learning Guidance (Full Prep)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Progress Track (Weekly)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Priority Support (Email Support)</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Exclusive Webinars</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Certificate of Completion</p>
                                    </div>
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-untick.png" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Team & Corporate Access</p>
                                    </div>
                                </div>
                                <a data-tracker="PricingStarter|Signup" href="#" target="_blank" className="white-button pricing logintrackbutton w-button">Purchase</a>
                            </div>
                            <div className="price-card">
                                <div className="div-block">
                                    <h3>Enterprise</h3>
                                    <img src="https://cdn.prod.website-files.com/63ca9a05fdc83042565f605c/63e3840698dcc96c89ceed60_personalized_design.svg" width="24" height="27" alt="Personalized design" className="image-2" />
                                </div>
                                <div className="price-tag">
                                    Contact Us
                                </div>
                                <p className="p-dec">EARLY BIRD PRICING</p>
                                <a href="#" className="price-btn">For Enterprise And Large Teams</a>
                                <p className="p-time">For any number of users</p>
                                <div className="pricing-points-contain">
                                    <div className="price-point-wrapper">
                                        <img width="28" height="28" src="./assets/images/right-tick.svg" loading="lazy" alt="Icon" className="pricing-checkmark" />
                                        <p>Get Tailored Solutions specific to your Organisation’s needs.</p>
                                    </div>
                                    <div className="price-point-wrapper"></div>
                                </div>
                                <a data-tracker="PricingStarter|Signup" href="/contact" target="_blank" className="white-button pricing logintrackbutton w-button">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="review typography">
                <div className="container">
                    <div className="cont-wrap">
                        <h2>Hear what <span className="txt-span-600">teams</span> around the world <span className="txt-span-600">saying</span> about <span className="txt-span-600">Courseific</span></h2>

                        <div className="owl-carousel review">
                            <div className="item">
                                <div className="home-testimonial-slide-wrapper">
                                    <img src="./assets/images/Quote.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon left" />
                                    <img src="./assets/images/Quote2.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon right" />

                                    <div className="testimonial-inside-wrapper">
                                        <div className="home-testimonial-star-wrapper">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                        <p className="p-20">Courseific has completely changed how we upskill our employees. The AI-powered learning paths save us so much time!</p>

                                        <div className="home-testimonial-author-name">
                                            <span>Rachel M., Learning & Development Manager at TechNova</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="home-testimonial-slide-wrapper">
                                    <img src="./assets/images/Quote.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon left" />
                                    <img src="./assets/images/Quote2.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon right" />

                                    <div className="testimonial-inside-wrapper">
                                        <div className="home-testimonial-star-wrapper">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                        <p className="p-20">Finding the right courses used to take hours. Now, it’s just seconds! Courseific is a game-changer for our training programs</p>

                                        <div className="home-testimonial-author-name">
                                            <span>James L., Head of Training at InnovateX </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="home-testimonial-slide-wrapper">
                                    <img src="./assets/images/Quote.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon left" />
                                    <img src="./assets/images/Quote2.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon right" />

                                    <div className="testimonial-inside-wrapper">
                                        <div className="home-testimonial-star-wrapper">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                        <p className="p-20">The combination of AI recommendations and human mentorship is brilliant. Our team stays engaged and actually completes their courses!</p>

                                        <div className="home-testimonial-author-name">
                                            <span>Sophie D., HR Lead at FinEdge Solutions</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="home-testimonial-slide-wrapper">
                                    <img src="./assets/images/Quote.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon left" />
                                    <img src="./assets/images/Quote2.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon right" />

                                    <div className="testimonial-inside-wrapper">
                                        <div className="home-testimonial-star-wrapper">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                        <p className="p-20">We’ve seen a 40% improvement in course completion rates since switching to Courseific. It keeps learners focused and motivated.</p>

                                        <div className="home-testimonial-author-name">
                                            <span>Daniel R., Learning Strategist at MedTech Global</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="home-testimonial-slide-wrapper">
                                    <img src="./assets/images/Quote.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon left" />
                                    <img src="./assets/images/Quote2.svg" loading="lazy" alt="Quote" className="testimonial-quote-icon right" />

                                    <div className="testimonial-inside-wrapper">
                                        <div className="home-testimonial-star-wrapper">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                        <p className="p-20">From coding bootcamps to healthcare certifications, Courseific helps our team find and complete the best courses fast. Highly recommended!</p>

                                        <div className="home-testimonial-author-name">
                                            <span>Anil S., Director of Workforce Development at SkillBridge</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="thought typography">
                <img src="./assets/images/thought-img-1.webp" alt="Template" className="cta-item-1-image" />
                <img src="./assets/images/thought-img-2.webp" className="cta-item-2-image" />
                <div className="wrapper">
                    <div className="cta-wrapper">
                        <h2 className="text-light">Start Learning at the speed of thought.</h2>
                        <div className="button-container">
                            <a href="#" className="btn primary-btn">Start for Free</a>
                            <img src="./assets/images/No Credit Card White Item.png" loading="lazy" alt="No credit card required" className="image-66" />
                        </div>
                    </div>
                </div>
            </section>

            <FooterWidget></FooterWidget>


            <link rel="stylesheet" href="./assets/css/main.css" />
            <link rel="stylesheet" href="./assets/css/responsive.css" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" />

            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.css" />

            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css" />
        </>
    }
}