import AbstractEntity from "./AbstractEntity";


export default class JobEntity extends AbstractEntity {
    constructor(object) {
        super();  // Call the parent constructor (if any)
        this.name = object.name;
        this.url = object.url;
    }

}