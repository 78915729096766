import AppConst from "../AppConst";
import AbstractEntity from "./AbstractEntity";
import CourseEntity from "./CourseEntity";
import JobEntity from "./JobEntity";

export default class MessageEntity extends AbstractEntity {
    static TABLE = 'messages';

    static schema = {
        uuid: "string",
        id: "number",
        _id: "number",
        conversationId: "number",
        content: "string",
        prompt: "string",
        role: "string",
        createdAt: "string",
        isFinish: "bool",
        courses: "array",
        jobs: "array",
    };

    constructor(object) {
        super();  // Call the parent constructor (if any)
        this.uuid = object.uuid ?? AbstractEntity.generateUID();
        this.id = object.id ?? 0;
        this._id = object._id ?? 0;
        this.conversationId = object.conversationId;
        this.content = object.content;
        this.prompt = object.prompt;
        this.role = object.role;
        this.isFinish = object.isFinish ?? false;
        this.courses = object.courses ? CourseEntity.fromList(object.courses ?? []) : null;
        this.jobs = CourseEntity.fromList(object.jobs ?? []);
        this.createdAt = object.createdAt ?? new Date().toISOString(); // Format as ISO string
    }


    toJson() {
        return JSON.stringify({
            "content": this.content,
            "role": this.role,
            "uuid": this.uuid,
            "conversation": AppConst.BASE_URL+ "/api/conversations/" + this.conversationId
        });
    }

    addWord(word) {
        this.content += word;
        return this;
    }

    isSynced = () => this.id !== 0;

    getJobs() {
        return JobEntity.fromList(this.jobs);
    }

    setError(error) {
        this.content = error;
        return this;
    }

    toWebinifyMessage() {
        return {
            "role": this.role,
            "content": this.content
        }
    }
}
