import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faClock, faCode, faGraduationCap, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import AppConfig from "../AppConfig";

import { MessageSender } from "../model/Message";
import ConversationRepository from "../repository/ConversationRepository";
import MessageRepository from "../repository/MessageRepository";
import AuthApi from "../api/AuthApi";
import AppHeader from "./widgets/AppHeader";
import LoginForm from "./widgets/LoginForm";
import WelcomeMessage from "./widgets/WelcomeMessage";

import ConversationContainerWidget from "./widgets/ConversationContainerWidget";
import ConversationEntity from "../entity/ConversationEntity";
import MessageEntity from "../entity/MessageEntity";
import MessageAPI from "../api/MessageApi";
import ConversationAPI from "../api/ConversationApi";

export default class Chat extends React.Component {
    constructor(props) {
        super(props);

        import("./chat.css");
        import("./chat.new.css");
        import("./chat.mobile.css");

        this.state = {
            isConversationLoaded: false,
            inputValue: '',
            isInputDisabled: false,
            isLoginFormActive: false,
            isLoggedIn: AuthApi.isLoggedIn(),
            conversation: null,
            conversations: [],
            jobsVisible: false,
            isCategoriesVisible: false,
        };

        this.promptExamples = [
            {
                "id": 1,
                "text": "Find me top Python Courses",
                "icon": <FontAwesomeIcon color="#FF5733" icon={faCode}></FontAwesomeIcon>
            },
            {
                "id": 2,
                "text": "Make me job ready in Javascript",
                'icon': <FontAwesomeIcon color="#33B5FF" icon={faLaptopCode}></FontAwesomeIcon>
            },
            {
                "id": 3,
                "text": "Give time management courses 1 hr max.",
                'icon': <FontAwesomeIcon color="#FFC300" icon={faClock}></FontAwesomeIcon>
            },
            {
                "id": 4,
                "text": "I want to practice GRE maths",
                'icon': <FontAwesomeIcon color="#28A745" icon={faGraduationCap}></FontAwesomeIcon>
            }
        ];
        this.messagesEndRef = React.createRef();


        this.indexDb = indexedDB.open(AppConfig.DATABASE_NAME, AppConfig.VERSION);
        this.db = null;

        /// https://www.youtube.com/watch?v=PqqkL_Lg41k&ab_channel=SteveGriffith-Prof3ssorSt3v3
        this.indexDb.addEventListener('upgradeneeded', (ev) => {
            this.db = ev.target.result;

            // Define your tables and their schemas
            let tables = {
                [ConversationEntity.TABLE]: ConversationEntity.schema,
                [MessageEntity.TABLE]: MessageEntity.schema,
            };

            // Loop through the tables and create each one if it doesn't exist
            Object.keys(tables).forEach((tableName) => {
                if (!this.db.objectStoreNames.contains(tableName)) {
                    const store = this.db.createObjectStore(tableName, { keyPath: "_id", autoIncrement: true });
                    Object.keys(tables[tableName]).forEach((key) => {
                        store.createIndex(key, key, { unique: false });
                    });
                    // console.log(`Table '${tableName}' created.`);
                } else {
                    // console.log(`Table '${tableName}' already exists.`);
                }
            });

            console.log(`Database upgraded to version ${this.db.version}`);
        });


        this.indexDb.addEventListener('success', (ev) => {
            // console.log('success');
            this.db = ev.target.result;

            this.conversationRepository = new ConversationRepository(this.db);
            this.messageRepository = new MessageRepository(this.db);

            if (AuthApi.isLoggedIn()) {
                ConversationAPI.getAll().then(async (conversations) => {

                    if (conversations !== undefined) {
                        let localConversations = ConversationEntity.fromList(await this.conversationRepository.getAll());
                        let localMessages = MessageEntity.fromList(await this.messageRepository.getAll());
                        conversations.forEach((conversation) => {
                            // Check if the conversation already exists
                            const exists = localConversations.some(
                                (localConversation) => localConversation.uuid === conversation.uuid
                            );

                            const updatedConversation = exists
                                ? new ConversationEntity(localConversations[0])
                                : conversation;

                            this.conversationRepository.update(updatedConversation);

                            conversation.messages.forEach((message) => {
                                // Check if the message already exists
                                const existingIndex = localMessages.findIndex(
                                    (localMessage) => localMessage.uuid === message.uuid
                                );

                                const updatedMessage = existingIndex !== -1
                                    ? new MessageEntity(localMessages[existingIndex])
                                    : message;

                                this.messageRepository.updateBy({ uuid: updatedMessage.uuid }, updatedMessage);;
                            });
                        });
                    }

                    this.conversationRepository.getConversations().then((res) => {
                        this.setConversations(res);
                    });

                });

                if (this.state.conversation == null) {
                    const urlParams = new URLSearchParams(window.location.search);
                    const conversationId = urlParams.get('id'); // '123'
                    if (conversationId !== undefined) {
                        this.conversationRepository.findOneBy({ uuid: conversationId }).then((conversationFound) => {
                            if (conversationFound !== null) {
                                let conversation = new ConversationEntity(conversationFound)
                                this.messageRepository.getBy({ conversationId: conversation.id }).then(messages => {
                                    conversation.messages = MessageEntity.fromList(messages);
                                    conversation.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                                    this.setConversation(conversation);
                                })
                            }
                        });

                    }
                }
            }

        });

        this.indexDb.addEventListener('error', (ev) => {
            // console.log('error');
        });

    }

    componentDidMount() {
      
    }

    handleSubmission = async (e) => {
        e.preventDefault(); // Prevents the default form submission behavior (page reload)
        const prompt = this.state.inputValue;
        this.triggerSearch(prompt);
    };


    triggerSearch = async (prompt) => {
        const isLoggedIn = AuthApi.isLoggedIn();
        if (!isLoggedIn) {
            this.setIsLoginFormActive(true);
        } else {
            let conversation = this.state.conversation;

            if (conversation == null) {
                // new conversation
                conversation = new ConversationEntity({ title: prompt });
                await ConversationAPI.post(conversation);
                await this.conversationRepository.add(conversation);
                conversation.setHistoryUrl();
                // you need to add message for the prompt
                let promptMessage = new MessageEntity({
                    conversationId: conversation.id,
                    content: "The user will prompt you to suggest courses. Just keep it simple. Dont suggest courses yourself. Just say something that we have courses as you are a course directory. Don't mention any course because we will show in a different prompt. So just keep it supportive and remain helpful.",
                    role: MessageSender.SYSTEM
                });
                await MessageAPI.post(promptMessage);
                this.messageRepository.add(promptMessage);
                conversation.addMessage(promptMessage);

                this.setConversation(conversation);
                this.setConversations(await this.conversationRepository.getConversations());
            } else {
                // this conversation exist already
                let messages = await this.messageRepository.getBy({ conversationId: conversation.id });
                conversation.messages = MessageEntity.fromList(messages);
                this.setConversation(conversation);
                this.setConversations(await this.conversationRepository.getConversations());
            }

            this.setInputValue('');
            this.setIsInputDisabled(true);
            const message = new MessageEntity({
                conversationId: conversation.id,
                content: prompt,
                role: MessageSender.USER
            });
            await this.messageRepository.add(message);
            conversation.addMessage(message);
            this.setConversation(conversation);
            this.setConversations(await this.conversationRepository.getConversations());
            this.scrollToBottom();

        }
    }

    onAssistantMessageAdded = async (message) => {
        let conversation = this.state.conversation;
        this.messageRepository.add(message);
        conversation.addMessage(message);

        this.setConversation(conversation);
        this.setConversations(await this.conversationRepository.getConversations());
        this.scrollToBottom();
    }

    onConversationClick = async (uuid) => {
        let conversation = await this.conversationRepository.findOneBy({ uuid: uuid });

        if (conversation) {
            this.setConversation(null);
            conversation = new ConversationEntity(conversation);
            conversation.setHistoryUrl();

            conversation.messages = MessageEntity.fromList(await this.messageRepository.getBy({ conversationId: conversation.id }));
            conversation.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

            this.setConversation(conversation);
            this.scrollToBottom();
        }
    }


    onNewConversationClicked = async () => {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete('id');
        window.history.replaceState({}, '', currentUrl.toString());

        this.setConversation(null);
        this.setConversations(await this.conversationRepository.getConversations());
    }

    deleteConversation = async (id) => {
        await this.conversationRepository.delete(id);
        let conversation = new ConversationEntity({ title: "" });

        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete('id');
        window.history.replaceState({}, '', currentUrl.toString());

        // await this.conversationRepository.add(conversation);
        this.setConversation(conversation);
        this.setConversations(await this.conversationRepository.getConversations());
    }

    _renderInput = () => {
        return <div className="chat-input-con">
            <form className="input-area" onSubmit={this.handleSubmission}>
                <input
                    type="text"
                    placeholder="What do you want to learn today?"
                    className="chat-input"
                    onKeyUp={this.onKeyUp}
                    disabled={this.state.isInputDisabled}
                    value={this.state.inputValue}  // Bind input value to state
                    onChange={(e) => this.setInputValue(e.target.value)}
                />

                <button className="submit-input">
                    <FontAwesomeIcon color="#000000" icon={faChevronRight}></FontAwesomeIcon>
                </button>
            </form>
        </div>;
    }

    /**
     * 
     * @param {MessageEntity} message 
     */
    onMessageFinish = async (message) => {
        await this.messageRepository.updateBy({ uuid: message.uuid }, message);
        if (message.isSynced() === false) {
            // try to sync the message with remote
            message = await MessageAPI.post(message);
            await this.messageRepository.updateBy({ uuid: message.uuid }, message);
        }
        this.setIsInputDisabled(false);
        this.setConversations(await this.conversationRepository.getConversations());
        this.scrollToBottom();
    }

    render() {
        return <>

            <div className="chat-app">
                <AppHeader isLoggedIn={this.state.isLoggedIn} setIsLoggedIn={this.setIsLoggedIn} setIsLoginFormActive={this.setIsLoginFormActive} deleteConversation={this.deleteConversation} onConversationClick={this.onConversationClick} conversations={this.state.conversations} onNewConversationClicked={() => this.onNewConversationClicked()} ></AppHeader>
                <div className="chat-main">
                    <div className="chat-con">

                        <div className="chat-listing">
                            {this.state.conversation && this.state.conversation.messages.length > 0 ?
                                <ConversationContainerWidget setConversation={this.setConversation} scrollToBottom={this.scrollToBottom} onAssistantMessageAdded={this.onAssistantMessageAdded} onMessageFinish={this.onMessageFinish} conversation={this.state.conversation} />
                                : <WelcomeMessage promptExamples={this.promptExamples} triggerSearch={this.triggerSearch} ></WelcomeMessage>}
                        </div>

                        <div ref={this.messagesEndRef} />
                    </div>
                    {this._renderInput()}

                </div>

            </div>
            <LoginForm isLoggedIn={this.state.isLoggedIn} setIsLoggedIn={this.setIsLoggedIn} setIsLoginFormActive={this.setIsLoginFormActive} isLoginFormActive={this.state.isLoginFormActive}></LoginForm>
        </>

    }


    setIsConversationLoaded = (value) => {
        this.setState({ isConversationLoaded: value });
    };

    setInputValue = (value) => {
        this.setState({ inputValue: value });
    };

    setIsInputDisabled = (value) => {
        this.setState({ isInputDisabled: value });
    };

    setIsLoginFormActive = (value) => {
        this.setState({ isLoginFormActive: value });
    };

    setIsLoggedIn = (value) => {
        this.setState({ isLoggedIn: value });
    };

    setConversation = (value) => {
        this.setState({ conversation: value });
    };

    setConversations = (value) => {
        this.setState({ conversations: value });
    };

    onKeyUp = (e) => {
        if (e.altKey === false && e.keyCode === 13 && e.key === "Enter") {
            this.handleSubmission(e);
        }
    }

    // Function to scroll to the bottom
    scrollToBottom = (timeout = 100) => {
        setTimeout(() => {
            this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, timeout);
    };
} 