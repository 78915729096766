import ConversationEntity from "../entity/ConversationEntity";
import RepositoryManager from "../tools/RepositoryManager";


export default class ConversationRepository extends RepositoryManager {
    constructor(indexDb) {
        super(indexDb, ConversationEntity.TABLE, ConversationEntity.schema);
    }

    /**
     * 
     * @returns {Promise<ConversationEntity[]>}
     */
    async getConversations() {
        const jsonConversations = await super.getAll();
        const conversations = ConversationEntity.fromList(jsonConversations ?? []);

        // Sort conversations by date
        conversations.sort((a, b) => new Date(b.date) - new Date(a.date));
        return conversations;
    }

    /**
     * 
     * @param {ConversationEntity[]} conversations 
     * @returns 
     */
    static categorizeConversations(conversations) {
        const today = this.getStartOfDay(new Date());
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const previous7Days = new Date(today);
        previous7Days.setDate(today.getDate() - 7);
        const previous30Days = new Date(today);
        previous30Days.setDate(today.getDate() - 30);

        const categories = {
            Today: [],
            Yesterday: [],
            'Previous 7 days': [],
            'Previous 30 days': [],
        };

        conversations.forEach(conversation => {
            const conversationDate = new Date(conversation.createdAt);
            const conversationStartOfDay = this.getStartOfDay(conversationDate);

            if (this.isSameDay(conversationStartOfDay, today)) {
                categories.Today.push(conversation);
            } else if (this.isSameDay(conversationStartOfDay, yesterday)) {
                categories.Yesterday.push(conversation);
            } else if (conversationStartOfDay >= previous7Days) {
                categories['Previous 7 days'].push(conversation);
            } else if (conversationStartOfDay >= previous30Days) {
                categories['Previous 30 days'].push(conversation);
            } else {
                const month = conversationDate.toLocaleString('default', { month: 'long' });
                const year = conversationDate.getFullYear();
                const monthYearKey = `${month} ${year}`;

                if (!categories[monthYearKey]) {
                    categories[monthYearKey] = [];
                }

                categories[monthYearKey].push(conversation);
            }
        });

        return categories;
    }

    static getStartOfDay(date) {
        const start = new Date(date);
        start.setUTCHours(0, 0, 0, 0);
        return start;
    }

    /**
     * 
     * @param {Date} date1 
     * @param {Date} date2 
     * @returns {boolean}
     */
    static isSameDay(date1, date2) {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }

}