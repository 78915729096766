
/**
 * @template T
 * @class AbstractEntity
 */
export default class AbstractEntity {
    // This method will convert the instance properties into a key-value structure
    toJson() {
        const json = {};
        Object.keys(this.constructor.schema).forEach((key) => {
            // Ensure the key is a string (if it's not already)
            json[String(key)] = this[key];
        });
        return json;
    }

    toDB() {
        const json = {};
        Object.keys(this.constructor.schema).forEach((key) => {
            // Ensure the key is a string (if it's not already)
            if(key === "_id" && this[key] === 0) {

            } else {
                json[String(key)] = this[key];
            }
        });
        return json;
    }

    static generateUID() {
        return Array.from(crypto.getRandomValues(new Uint8Array(16))) // Generate 16 random bytes
        .map(byte => byte.toString(16).padStart(2, '0')) // Convert each byte to a 2-digit hex string
        .join('') // Join all hex strings together
        .replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5'); // Insert hyphens in the correct places
    }
    
    
     /**
     * Convert a list of plain objects into instances of the current class.
     * @param {Object[]} objectList - List of plain objects.
     * @returns {T[]} - Array of instances of the current class.
     */
     static fromList(objectList = []) {
        if (!Array.isArray(objectList) || objectList.length === 0) return [];
        return objectList.map((obj) => new this(obj));
    }
}