import AbstractEntity from "./AbstractEntity";
import MessageEntity from "./MessageEntity";

export default class ConversationEntity extends AbstractEntity {
    static TABLE = 'conversations';

    static schema = {
        uuid: "string",
        id: "number",
        _id: "number",
        title: "string",
        createdAt: "string"
    };

    constructor(object) {
        super();  // Call the parent constructor (if any)
        this.uuid = object.uuid ?? AbstractEntity.generateUID();
        this.id = object.id ?? 0;
        this._id = object._id ?? 0;
        this.title = object.title;
        this.messages = object.messages ? MessageEntity.fromList(object.messages) : [];
        this.createdAt = object.createdAt ?? new Date().toISOString(); // Format as ISO string
    }

    toJson() {
        return JSON.stringify(this);
    }

    isSynced = () => this.id !== 0;

    getMessages() {
        this.messages.sort((a, b) => a.createdAt > b.createdAt);
        return this.messages;
    }

    /**
     * 
     * @param {MessageEntity} message 
     */
    addMessage(message) {
        this.messages.push(message);
    }

    addWord(word) {
        this.title += word;
        return this;
    }

    setHistoryUrl() {
        const conversationId = this.uuid;
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('id', conversationId);
        window.history.replaceState({}, '', currentUrl.toString());
    }

}