import React from "react";

export default class LoginPage extends React.Component {
    componentDidMount() {
        import("./LoginPage.css");
    }

    render() {
        return <>
            <div class="login-container">

                <div class="login-box">
                    <div class="logo">
                        <img src="https://www.courseific.com/blog/wp-content/uploads/2024/09/logo-no-background-1.svg" alt="" />
                    </div>
                    <div class="login-wrape">
                        <p class="text-primary text-center linear-text"><strong>🎉 Sign up now and get 200 credits free!
                            🎉</strong></p>
                        <h3 class="text-center login-title">Create your free account or login below</h3>

                        <button class="btn btn-social btn-google">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                                        fill="#FFC107"></path>
                                    <path
                                        d="M3.153 7.3455L6.4385 9.755C7.3275 7.554 9.4805 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.159 2 4.828 4.1685 3.153 7.3455Z"
                                        fill="#FF3D00"></path>
                                    <path
                                        d="M12 22C14.583 22 16.93 21.0115 18.7045 19.404L15.6095 16.785C14.6055 17.5455 13.3575 18 12 18C9.399 18 7.1905 16.3415 6.3585 14.027L3.0975 16.5395C4.7525 19.778 8.1135 22 12 22Z"
                                        fill="#4CAF50"></path>
                                    <path
                                        d="M21.8055 10.0415H21V10H12V14H17.6515C17.2555 15.1185 16.536 16.083 15.608 16.7855C15.6085 16.785 15.609 16.785 15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                                        fill="#1976D2"></path>
                                </svg>
                            </div>
                            Continue with Google
                        </button>

                        <button class="btn btn-social btn-linkedin">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M19.04 19.0438H16.0775V14.4025C16.0775 13.295 16.055 11.8713 14.535 11.8713C12.9913 11.8713 12.755 13.0763 12.755 14.3213V19.0438H9.7925V9.5H12.6375V10.8H12.675C13.0725 10.05 14.04 9.25875 15.4837 9.25875C18.4837 9.25875 19.04 11.2338 19.04 13.805V19.04V19.0438ZM6.4475 8.19375C5.495 8.19375 4.7275 7.4225 4.7275 6.4725C4.7275 5.525 5.495 4.75375 6.4475 4.75375C7.3975 4.75375 8.1675 5.525 8.1675 6.4725C8.1675 7.4225 7.39625 8.19375 6.4475 8.19375ZM7.9325 19.0438H4.9625V9.5H7.9325V19.0438ZM20.52 2H3.475C2.66 2 2 2.645 2 3.44125V20.5588C2 21.355 2.66 22 3.475 22H20.5187C21.3337 22 22 21.355 22 20.5588V3.44125C22 2.645 21.3337 2 20.5187 2H20.52Z"
                                        fill="#0A66C2"></path>
                                </svg>
                            </div>
                            Continue with LinkedIn
                        </button>

                        <button class="btn btn-social btn-msteams">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M22 11V15C22 16.657 20.657 18 19 18C17.343 18 16 16.657 16 15V10H21C21.5525 10 22 10.4475 22 11ZM19 8C20.1045 8 21 7.1045 21 6C21 4.8955 20.1045 4 19 4C17.8955 4 17 4.8955 17 6C17 7.1045 17.8955 8 19 8Z"
                                        fill="#5059C9"></path>
                                    <path
                                        d="M17.5 11V16.5C17.5 19.3715 15.0795 21.678 12.167 21.489C9.5095 21.317 7.5 18.9915 7.5 16.3285V10H16.5C17.0525 10 17.5 10.4475 17.5 11ZM12.5 8.5C14.157 8.5 15.5 7.157 15.5 5.5C15.5 3.843 14.157 2.5 12.5 2.5C10.843 2.5 9.5 3.843 9.5 5.5C9.5 7.157 10.843 8.5 12.5 8.5Z"
                                        fill="#7B83EB"></path>
                                    <path
                                        d="M12.5 8.5C14.1569 8.5 15.5 7.15685 15.5 5.5C15.5 3.84315 14.1569 2.5 12.5 2.5C10.8431 2.5 9.5 3.84315 9.5 5.5C9.5 7.15685 10.8431 8.5 12.5 8.5Z"
                                        fill="#7B83EB"></path>
                                    <path opacity="0.05"
                                        d="M13 16.6595V10H7.5V16.3285C7.5 17.0955 7.6715 17.8325 7.972 18.5H11.159C12.176 18.5 13 17.676 13 16.6595Z"
                                        fill="black"></path>
                                    <path opacity="0.07"
                                        d="M7.5 10V16.3285C7.5 16.9085 7.6005 17.4705 7.777 18H11.106C11.968 18 12.6665 17.3015 12.6665 16.4395V10H7.5Z"
                                        fill="black"></path>
                                    <path opacity="0.09"
                                        d="M12.3335 10H7.5V16.3285C7.5 16.7295 7.5505 17.1205 7.637 17.5H11.053C11.76 17.5 12.333 16.927 12.333 16.22V10H12.3335Z"
                                        fill="black"></path>
                                    <path
                                        d="M11 17H3C2.4475 17 2 16.5525 2 16V8C2 7.4475 2.4475 7 3 7H11C11.5525 7 12 7.4475 12 8V16C12 16.5525 11.5525 17 11 17Z"
                                        fill="url(#paint0_linear_9_214)"></path>
                                    <path d="M9.034 9.49951H4.966V10.3595H6.4895V14.4995H7.5105V10.3595H9.034V9.49951Z"
                                        fill="white"></path>
                                    <defs>
                                        <linearGradient id="paint0_linear_9_214" x1="2.324" y1="7.324" x2="11.7015" y2="16.7015"
                                            gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#5961C3"></stop>
                                            <stop offset="1" stop-color="#3A41AC"></stop>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            Continue with MS Teams
                        </button>

                        <button class="btn btn-social btn-email">
                            <div class="icon">

                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2 3.00544C2 2.44716 2.44985 2 3 2H17C17.5502 2 18 2.44716 18 3.00544V12.9956C18 13.5525 17.5505 14 17 14H3C2.44985 14 2 13.5528 2 12.9946V3.00544ZM3 16H17C18.6595 16 20 14.6526 20 12.9956V3.00544C20 1.34772 18.6598 0 17 0H3C1.34015 0 0 1.34772 0 3.00544V12.9946C0 14.6523 1.34015 16 3 16ZM5.51449 4.14251C5.04091 3.85836 4.42665 4.01192 4.14251 4.4855C3.85836 4.95908 4.01192 5.57334 4.4855 5.85749L9.4855 8.85749C9.80219 9.0475 10.1978 9.0475 10.5145 8.85749L15.5145 5.85749C15.9881 5.57334 16.1416 4.95908 15.8575 4.4855C15.5733 4.01192 14.9591 3.85836 14.4855 4.14251L10 6.83381L5.51449 4.14251Z"
                                        fill="#262626"></path>
                                </svg>
                            </div>
                            Continue with Email
                        </button>

                        <div class="form-check text-center">
                            <input class="form-check-input" type="checkbox" id="terms" />
                            <label class="form-check-label checkbox-label" for="terms">
                                I agree to Courseific <a href="#">terms</a> & <a href="#">privacy policy</a>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="ft-block">
                    <div class="ft-block-wrap">


                        <h3 class="mt-5"><strong>Master Any Skill </strong> with AI-Powered
                            <span class="text-primary">Learning Paths 🚀</span></h3>
                        <div class="feature-list mt-4">
                            <div class="list">
                                <p><strong>🔍 Find the Perfect Course:</strong> Instantly discover top courses across any niche.
                                </p>
                            </div>
                            <div class="list">
                                <p><strong>🎯 Stay on Track:</strong> Follow a structured learning path tailored to your goals.
                                </p>
                            </div>
                            <div class="list">
                                <p><strong>👩‍🏫 Get Expert Support:</strong> Real human mentors help you stay motivated.</p>
                            </div>
                            <div class="list">
                                <p><strong>📊 Track Your Progress:</strong> Smart reminders and milestones keep you moving
                                    forward.</p>
                            </div>
                            <div class="list">
                                <p><strong>📱 Learn Anytime, Anywhere:</strong> Access courses seamlessly on any device.</p>
                            </div>
                        </div>

                        <p class="login-left-bottom text-center mt-5"><strong>💡 Join thousands of learners worldwide </strong>
                            who trust Courseific to achieve their goals!</p>
                    </div>
                </div>
            </div >

            <link rel="stylesheet" href="./assets/css/main.css" />
            <link rel="stylesheet" href="./assets/css/responsive.css" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" />

            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.css" />

            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css" />

        </>
    }
}