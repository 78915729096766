import AbstractEntity from "./AbstractEntity";

export default class CourseEntity extends AbstractEntity {
    constructor(object) {
        super(object);
        this.id = object.id ?? 0;
        this.title = object.title;
        this._targetUrl = object.targetUrl ?? object._targetUrl ?? "null";
        this.thumbnailUrl = object.thumbnailUrl;
        this.language = object.language;
        this.taughtBy = object.taughtBy;
        this.type = object.type ?? "Unknown";
        this.duration = object.duration ?? "N/A";
        this.courseSessions = object.courseSessions ?? "N/A";
        this.level = object.level ?? "All Levels";

        this.categories = CategoryEntity.fromList(object.categories ?? []);
        this.providers = object.providers ?? [];
        this.createdAt = object.createdAt ?? new Date().toISOString(); // Format as ISO string
    }

    get firstAffiliateParam() {
        if (this.providers.length > 0) {
            return this.providers[0]?.affiliateQueryParam || null;
        }
        return null;
    }

    get targetUrl() {
        const url = new URL(this._targetUrl);
        const currentDomain = window.location.hostname;
        const affiliateQueryParam = this.firstAffiliateParam;

        // Set UTM parameters
        url.searchParams.set('utm_source', currentDomain);
        url.searchParams.set('utm_medium', 'affiliate');
        url.searchParams.set('utm_campaign', 'referral');

        // If affiliateQueryParam exists, extract key-value pair and set it
        if (affiliateQueryParam) {
            const [key, value] = affiliateQueryParam.split('=');
            if (key && value) {
                url.searchParams.set(key, value);
            }
        }

        return url.toString();
    }


    get provider() {
        return this.providers?.map(provider => provider.name).join(", ") || "Unknown"
    }

    // Setter for categories
    set categories(cats) {
        if (!Array.isArray(cats)) {
            throw new Error("Categories must be an array");
        }
        this._categories = CategoryEntity.fromList(cats);
    }

    // Getter for categories
    get categories() {
        return this._categories;
    }

    getCategoryNames() {
        //return Object.values(this.categories).join(', ');
        const categoryNames = this.categories.map((category) => category.name);

        // Get the last entry
        const lastEntry = categoryNames[categoryNames.length - 1];
        return lastEntry;
    }

}


class CategoryEntity extends AbstractEntity {
    constructor(object) {
        super();
        this.name = object.name;
        this.slug = object.slug;
    }
}